import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';

import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { PricingCards, PricingOverlayContainer } from 'components/PricingCards';
import { GatsbyLink } from 'components/Links';
import { PricingTable } from 'components/Table';

import HeroImage from 'images/pricing/pricing-hero.inline.svg';
import ClientPortfoliosImage from 'images/pricing/managing-client-portfolios.inline.svg';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const Pricing: FC<LocalizedPageProps> = () => {
  const theme = useTheme();

  const {
    translations: { currencySymbol },
  } = useContentfulLocale();
  const currency = currencySymbol || '$';

  const isMobile = !!useMediaQuery(theme.breakpoints.down('md'));

  return (
    /*
     * This is to avoid having two consecutive CTAs, as the whole
     * 'Compare plans and features' section is hidden on mobile
     */
    <Layout hideFooterCta>
      <Seo />

      <HeroContainer background="navyBlue" wrapMobile="hard">
        <Section>
          <h1>Pricing & plans</h1>
          <p>Our portfolio plans grow along with you in your investing journey.</p>
        </Section>
        <SectionImage image={<HeroImage />} boxShadow={false} borderRadius={false} />
      </HeroContainer>
      <PricingOverlayContainer>
        <Section>
          <PricingCards />
        </Section>
      </PricingOverlayContainer>

      {!isMobile && (
        <Container>
          <Section>
            <h2>Compare plans and features</h2>
            <PricingTable />
          </Section>
        </Container>
      )}

      <Container background="navyBlue">
        <Section>
          <h2>Managing client portfolios?</h2>
          <p>
            Pay for what you need. Whether you track 5 portfolios or 500, you never pay more than{' '}
            {currency}13.50 per portfolio, per month.
          </p>
          <br />
          <GatsbyLink
            to="/professionals/#pricing"
            asButton
            appearance="secondary"
            containerBackground="negative"
            showArrowIcon
          >
            Professional pricing
          </GatsbyLink>
        </Section>
        <SectionImage
          firstOnMobile
          image={<ClientPortfoliosImage />}
          boxShadow={false}
          borderRadius={false}
        />
      </Container>
    </Layout>
  );
};

export default Pricing;
